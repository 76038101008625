// Form.tsx

// 型を個別に指定
type FormPropsType = {
  city: string | undefined;
  setCity: React.Dispatch<React.SetStateAction<string | undefined>>;
  getWeather: (e: React.FormEvent<HTMLFormElement>) => void;
}

const Form = ({ city, setCity, getWeather }: FormPropsType) => {
    return (
    <form onSubmit={getWeather}>
      <input type="text" name="city" placeholder="都市名" onChange={e => setCity(e.target.value)} value={city}/>
      <button type="submit">Get Weather</button>
    </form>
  );
};

export default Form;