// Loading.tsx

// 省略形
const Loading = () => <div className="loading"></div>

  // const Loading = () => {
  //   return (
  //     <h1>通信中...</h1>
  //   );
  // };

export default Loading;