// Title.tsx

// 省略形
const Title = () => <h1>React World Weather</h1>;

  // const Title = () => {
  //   return (
  //     <h1>React World Weather</h1>
  //   );
  // };

export default Title;